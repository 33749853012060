<template>
  <v-card class="mt-10">
    <v-row class="d-row-from ma-0">
      <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
        <span class="d-label-left ml-3">体験宿泊希望日時</span>
        <v-chip class="ma-2 d-label-optional-bg" label text-color="white">
          {{ $t('common.labelOptional') }}
        </v-chip>
      </div>
      <div class="d-single-row">
        <div class="flex-fill ml-3 box-form-trial-stay-wap">
          <v-col cols="12" md="12" sm="12" class="ma-0 pa-0">
            <v-row class="pa-0 ma-0">
              <v-col sm="12" md="2" cols="12" class="item-label pr-0 align-self-center pb-0">
                <label class="">第１希望</label>
              </v-col>
              <v-col sm="12" md="4" cols="12" class="ma-0 pa-0 item-input-row">
                <v-menu
                  v-model="menuFirstDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      class="ma-0 pa-0"
                      label="日付を選択"
                      hint="日付を選択"
                      v-model="vModelDataFormTrialStay.firstCheckInDate"
                      @input="handlerDataFiled"
                      @blur="handlerDataFiled"
                      persistent-hint
                      readonly
                      solo
                      clearable
                      v-on="on"
                      hide-details
                    >
                      <template v-slot:append>
                        <v-img width="" height="" class="ma-0 pa-0" v-on="on"
                               src="@/assets/images/icon-calendar-alt.svg"
                               contain
                        />
                      </template>
                      <template v-slot:append-outer>
                        <span class="ml-1 mt-3" style="min-width: 40px">から</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vModelDataFormTrialStay.firstCheckInDate"
                    no-title
                    :min="nowDate"
                    @input="menuFirstDate = false"
                  />
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="2" class="ma-0 pa-0 item-input-row">
                <v-select
                  class="auto-line-row item-input-center-pr-3"
                  v-model="vModelDataFormTrialStay.firstStayDays"
                  @change="handlerDataFiled"
                  :items="stayDays"
                  hide-details="auto"
                  solo
                >
                  <template v-slot:append>
                    <v-img width="" height="" class="ma-0 pa-0"
                           src="@/assets/images/icon-arrow-down.svg"
                           contain
                    />
                  </template>
                  <template v-slot:append-outer>
                    <span class="ma-0 ml-1 mt-3">泊</span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="ma-0 pa-0 item-input-row">
                <v-select
                  class="auto-line-row item-input-last-pr-6"
                  v-model="vModelDataFormTrialStay.firstFacility"
                  @change="handlerDataFiled"
                  :items="publicFacilityList"
                  item-text="name"
                  return-object
                  label="選択して下さい"
                  hide-details="auto"
                  solo
                >
                  <template v-slot:append>
                    <v-img width="" height="" class="ma-0 pa-0"
                           src="@/assets/images/icon-arrow-down.svg"
                           contain
                    />
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row class="pa-0 ma-0 mt-4">
              <v-col sm="12" md="2" cols="12" class="item-label pr-0 align-self-center pb-0">
                <label class="">第２希望</label>
              </v-col>
              <v-col sm="12" md="4" cols="12" class="ma-0 pa-0 item-input-row">
                <v-menu
                  v-model="menuSecondDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="vModelDataFormTrialStay.secondCheckInDate"
                      @input="handlerDataFiled"
                      @blur="handlerDataFiled"
                      class="ma-0 pa-0"
                      label="日付を選択"
                      hint="日付を選択"
                      persistent-hint
                      readonly
                      solo
                      clearable
                      hide-details
                      v-on="on"
                    >
                      <template v-slot:append>
                        <v-img width="" height="" class="ma-0 pa-0" v-on="on"
                               src="@/assets/images/icon-calendar-alt.svg"
                               contain
                        />
                      </template>
                      <template v-slot:append-outer>
                        <span class="ml-1 mt-3" style="min-width: 40px">から</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vModelDataFormTrialStay.secondCheckInDate"
                    no-title
                    :min="nowDate"
                    @input="menuSecondDate = false"
                  />
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="2" class="ma-0 pa-0 item-input-row">
                <v-select
                  class="auto-line-row item-input-center-pr-3"
                  v-model="vModelDataFormTrialStay.secondStayDays"
                  @change="handlerDataFiled"
                  :items="stayDays"
                  hide-details="auto"
                  solo
                >
                  <template v-slot:append>
                    <v-img width="" height="" class="ma-0 pa-0"
                           src="@/assets/images/icon-arrow-down.svg"
                           contain
                    />
                  </template>
                  <template v-slot:append-outer>
                    <span class="ma-0 ml-1 mt-3">泊</span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="ma-0 pa-0 item-input-row">
                <v-select
                  class="auto-line-row item-input-last-pr-6"
                  v-model="vModelDataFormTrialStay.secondFacility"
                  @change="handlerDataFiled"
                  :items="publicFacilityList"
                  item-text="name"
                  return-object
                  label="選択して下さい"
                  hide-details="auto"
                  solo
                >
                  <template v-slot:append>
                    <v-img width="" height="" class="ma-0 pa-0"
                           src="@/assets/images/icon-arrow-down.svg"
                           contain
                    />
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </div>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import C from '@/utils/constant'

export default {
  data () {
    return {
      menuFirstDate: false,
      menuSecondDate: false,
      nowDate: new Date().toISOString().slice(0, 10),
      vModelDataFormTrialStay: {
        firstCheckInDate: '',
        firstStayDays: 1,
        firstFacility: '',
        secondCheckInDate: '',
        secondStayDays: 1,
        secondFacility: ''
      },
      stayDays: [...Array(process.env.MAX_TRIAL_STAY_DAYS || 2).keys()].map(i => i + 1)
    }
  },
  model: {
    event: ['eventChangeDataTrialStayRequest']
  },
  props: {
    dataModel: {
      type: Object,
      required: false
    }
  },
  mounted () {
    this.initEnumList()

    if (this.dataModel && Object.keys(this.dataModel).length) {
      this.vModelDataFormTrialStay = this.dataModel
    }
  },
  methods: {
    initEnumList () {
      this.$store.dispatch('fetchEnumPrefectureListDispatch', { apolloClient: this.$apollo, toast: this.$toast })
      this.$store.dispatch('fetchPublicFacilityListDispatch', { apolloClient: this.$apollo, toast: this.$toast })
    },
    handlerDataFiled () {
      this.eventDataPush()
    },
    eventDataPush () {
      this.$nextTick(() => {
        this.$emit('eventChangeDataTrialStayRequest', {
          data: this.vModelDataFormTrialStay,
          isValid: true // Optional
        })
      })
    }
  },
  computed: {
    ...mapState({
      // Format [stageName] => state.[modulesName].[stageName],
      enumPrefectures: state => state.main.enumPrefectures,
      publicFacilityList: state => state.main.publicFacilityList
    }),
    constant () {
      return Object.assign({}, C)
    },
    prefectureItems () {
      return this.enumPrefectures
    }
  }
}
</script>

<style lang="scss">

</style>
