<template>
  <div class="document-request-ts">
    <Header :typeComponent="'online-consultation'"/>
    <v-col>
      <v-row class="flex-fill">
        <BaseContentIntro>
          <template v-slot:title>
            <span class="color-title">{{ $t('documentRequest.triaStay.settingsTitle') }}</span>
          </template>
          <template v-slot:default>
            <v-row class="flex-fill pa-9 pb-0 pt-6">
              <v-col sm="7" md="7" cols="12">
                <v-row class="flex-fill align-start d-block">
                  <p class="mb-1">ご購入検討の方には、特別料金で体験宿泊をご用意しております。</p>
                </v-row>

                <v-row class="flex-fill align-start d-block pt-6">
                  <p class="mb-1 font-weight-bold">体験宿泊料金</p>
                  <p class="mb-1">施設や宿泊日で料金が異なります。</p>
                  <p class="mb-1">申込頂いた後に営業担当者からご案内させていただきます。</p>
                </v-row>

                <v-row class="flex-fill align-start d-block pt-6">
                  <p class="mb-1 font-weight-bold">ご予約開始日</p>
                  <p class="mb-1">宿泊希望日の2ヶ月前から受け付けております。なお、年末年始・ゴールデンウィーク・夏休みなどの繁忙期は、原則除外させていただきます。</p>
                </v-row>

              </v-col>
              <v-col sm="5" md="5" cols="12" class="d-flex align-center img-right pa-0 ma-0">
                <v-img width="312" height="208" class="ma-0 pa-0"
                       src="@/assets/images/intro-trial-stay.svg"
                       contain
                />
              </v-col>
            </v-row>

            <v-row class="flex-fill pa-9 pb-0 pt-6 mb-3">
              <div class="d-block text-start text-red">
                <p class="ma-0">本フォームでのお申込みは、体験宿泊のご予約を確定するものではありません。後日、改めて担当者よりご連絡させていただきます。</p>
                <p class="ma-0">Please fill out the form below to apply for trial accommodation; available for resident in Japan, only.</p>
              </div>
            </v-row>
          </template>
        </BaseContentIntro>
      </v-row>
    </v-col>

    <BreadcrumbStep :dataItems="dataBreadcrumbs" @eventChangeStep="handlerClickStep"/>
    <template v-if="stepTrialStayType === constant.STEP_BY_STEP.STEP_1">
      <p class="step1"></p>
      <MainFormDocRequest @eventChangeDataDocReq="handlerMainFormDocRequest"
                          :dataModel="dataMainFormDocRequest"/>

      <FormDividerDuplication/>

      <MainFormTrialStay @eventChangeDataTrialStayRequest="handlerTrialStayOptional"
                                       :dataModel="dataTrialStayOptional"/>
      <FormDividerDuplication/>

      <PolicyAgree @eventPolicyAgree="handlerPolicyAgree"
                   :dataModel="dataPolicyAgree"/>

      <v-col class="mt-1">
        <p class="pa-0 ma-0 text-center">本フォームでのお申込みは、体験宿泊のご予約を確定するものではありません。</p>
        <p class="pa-0 ma-0 text-center">後日、改めて担当者よりご連絡させていただきます</p>
      </v-col>

      <v-col class="mt-1">
        <p class="pa-0 ma-0 text-center">Please fill out the form below to apply for trial accommodation;</p>
        <p class="pa-0 ma-0 text-center">available for resident in Japan, only.</p>
      </v-col>

      <v-col class="mt-2 d-flex justify-center align-center d-box-act mb-9">
        <v-btn class="btn-action-from pa-0 pl-2" color="primary" :disabled="valid" @click="funcConfirmStep1Form">
          <v-icon x-large right color="white" class="b-right">mdi-chevron-right</v-icon>
          {{ $t('buttons.confirmAndSend') }}
        </v-btn>
      </v-col>
    </template>

    <template v-if="stepTrialStayType === constant.STEP_BY_STEP.STEP_2">
      <p class="step2"></p>
      <ShowDataTrialStayStep2 :type="typeApply"/>

      <v-row class="d-flex justify-center align-center d-box-act mt-4 mb-9">
        <v-col class="d-flex justify-center align-center d-box-act">
          <v-btn class="btn-action-from pa-0 pl-2" text @click="funcBackStep1Form" :disabled="noClickStep2">
            <v-icon x-large right color="white" class="b-right">mdi-chevron-left</v-icon>
            {{ $t('buttons.back') }}
          </v-btn>
        </v-col>
        <v-col class="d-flex justify-center align-center d-box-act">
          <v-btn class="ml-2 pl-2 btn-action-from pa-0" color="primary" @click.prevent="funcSubmitStep2Form" :disabled="noClickStep2">
            <v-icon x-large right color="white" class="b-right">mdi-chevron-right</v-icon>
            {{ $t('buttons.saveAndCreate') }}
            <v-progress-circular v-if="noClickStep2" indeterminate color="primary" :size="18" :width="2"/>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-if="stepTrialStayType === constant.STEP_BY_STEP.STEP_3">
      <ShowDataStep3 />
      <!--      <v-col class="d-flex justify-center align-center d-box-act">-->
      <!--        <v-btn class="btn-action-from" text @click="funcAfterSubmitStep3Form">-->
      <!--          <v-icon x-large right color="white" class="b-right">mdi-chevron-right</v-icon>-->
      <!--          TODO: After Submit success ....-->
      <!--        </v-btn>-->
      <!--      </v-col>-->
    </template>

    <Footer :typeComponent="'form'"/>
  </div>
</template>

<script>
import BaseContentIntro from '@/components/common/BaseContentIntro'
import BreadcrumbStep from '@/components/common/BreadcrumbStep'
import PolicyAgree from '@/components/common/PolicyAgree'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import MainFormTrialStay from '@/components/documentRequest/trialStay/MainFormTrialStay'
import ShowDataTrialStayStep2 from '@/components/documentRequest/trialStay/ShowDataTrialStayStep2'
import MainFormDocRequest from '@/components/common/MainFormDocRequest'
import ShowDataStep3 from '@/components/common/ShowDataStep3'
import FormDividerDuplication from '@/components/common/FormDividerDuplication'
import { mapState } from 'vuex'
import createWebRequest from '@/mixins/createWebRequest'

export default {
  mixins: [
    createWebRequest
  ],
  data () {
    return {
      noClickStep2: false,
      typeValidMainFormDocRequest: 'REQ_CHECKBOX_MFEA',
      typeValidPolicyAgree: 'REQ_CHECKBOX_PA'
    }
  },
  components: {
    MainFormTrialStay,
    ShowDataStep3,
    ShowDataTrialStayStep2,
    FormDividerDuplication,
    MainFormDocRequest,
    BreadcrumbStep,
    BaseContentIntro,
    PolicyAgree,
    Footer,
    Header
  },
  mounted () {
    this.$store.dispatch('stepTrialStayTypeDispatch', this.constant.STEP_BY_STEP.STEP_1)
    this.typeValidPolicyAgree = this.dataTrialStay.policyAgree ? '' : 'REQ_CHECKBOX_PA'
  },
  computed: {
    ...mapState({
      // Format [stageName] => state.[modulesName].[stageName],
      stepTrialStayType: state => state.documentRequest.stepTrialStayType
    }),
    dataPolicyAgree () {
      // Get data for v-model
      return this.dataTrialStay.policyAgree
    },
    dataMainFormDocRequest () {
      // Get data for v-model
      return this.dataTrialStay.mainFormDocRequest
    },
    dataTrialStayOptional () {
      // Get data for v-model
      return this.dataTrialStay.trialStayOptional
    },
    dataBreadcrumbs () {
      return [{
        step: 1,
        name: this.$t('documentRequest.stepByStep.step1'),
        active: this.constant.STEP_BY_STEP.STEP_1 === this.stepTrialStayType
      }, {
        step: 2,
        name: this.$t('documentRequest.stepByStep.step2'),
        active: this.constant.STEP_BY_STEP.STEP_2 === this.stepTrialStayType
      }, {
        step: 3,
        name: this.$t('documentRequest.stepByStep.step3'),
        active: this.constant.STEP_BY_STEP.STEP_3 === this.stepTrialStayType
      }]
    },
    valid () {
      return [
        this.typeValidPolicyAgree,
        this.typeValidMainFormDocRequest
      ].some(ele => ele.length)
    }
  },
  methods: {
    handlerClickStep (data) {
      // console.log(data)
    },
    handlerPolicyAgree (dataBoolean) {
      this.typeValidPolicyAgree = dataBoolean ? '' : 'REQ_CHECKBOX_PA'
      this.$store.dispatch('policyAgreeDispatch', { policyAgree: dataBoolean, typeApply: this.typeApply })
    },
    handlerMainFormDocRequest (data) {
      this.typeValidMainFormDocRequest = data?.isValid && data.isValid ? '' : 'REQ_CHECKBOX_MFEA'
      this.$store.dispatch('mainFormTrialStayRequestDispatch', data.data)
    },
    handlerTrialStayOptional (data) {
      this.$store.dispatch('trialStayOptionalRequestDispatch', data.data)
    },
    funcConfirmStep1Form () {
      this.$store.dispatch('stepTrialStayTypeDispatch', this.constant.STEP_BY_STEP.STEP_2)
      this.scrollIntoViewSmooth('.step2')
    },
    funcBackStep1Form () {
      this.$store.dispatch('stepTrialStayTypeDispatch', this.constant.STEP_BY_STEP.STEP_1)
      this.scrollIntoViewSmooth('.step1')
    },
    async funcSubmitStep2Form () {
      if (this.noClickStep2) {
        this.showMessageDbClick(this.$toast, 'w', this.$t('common.notSaveWhenDbClick'))
        return
      }
      this.noClickStep2 = true
      const payload = await this.convertDataBeforeForTrialStay()

      await this.$store.dispatch('createWebRequestDispatch', {
        apolloClient: this.$apollo,
        payload,
        typeApply: this.typeApply,
        toast: this.$toast
      }).then(res => {
        if (res) {
          this.scrollIntoViewSmooth('.step3')
          return
        }

        // eslint-disable-next-line no-return-assign
        setTimeout(() => this.noClickStep2 = false, 3200)
      })
    },
    funcAfterSubmitStep3Form () {
      // TODO
    }
  },
  created () {
    // Setup for component use data
    this.typeApply = 'TRIAL_STAY'
  }
}
</script>

<style lang="scss">
.d-duplication-divider {
  margin-top: 48px;
}
.cust-vchip-online {
  background: #004BB1 !important;
  span.v-chip__content {
    padding: 5px 10px;
  }
}
</style>
