<template>
  <div class="document-request-trial-stay-show-step2">
    <v-card class="mt-30" v-if="hasExistItemData">
      <v-card-text class="ma-0 pa-0">
        <v-row class="d-row-from-border ma-0">
          <v-col cols="12" md="3" sm="2" class="bg-main-from d-flex justify-space-between align-center pa-0">
            <span class="d-label-left ml-3">体験宿泊希望日時</span>
            <v-chip class="ma-2 d-label-optional-bg" label text-color="white">
              {{ $t('common.labelOptional') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" cols="12" :class="[{'d-flex align-center': !(hasExistItemDataRow1 && hasExistItemDataRow2)}]">
            <v-row class="bd-from-bottom" v-if="hasExistItemDataRow1">
              <v-col md="2" sm="2" cols="4" class="bd-from-right text-end">
                <span class="d-label-left ml-3">第１希望</span>
              </v-col>
              <v-col md="10" sm="10" cols="8" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap">{{ trialStayOptional.firstCheckInDate || '-' }} / {{ firstStayDays }}泊 / {{ firstFacilityText }}</p>
              </v-col>
            </v-row>

            <v-row class="bd-from-bottom" v-if="hasExistItemDataRow2">
              <v-col md="2" sm="2" cols="4" class="bd-from-right text-end">
                <span class="d-label-left ml-3">第２希望</span>
              </v-col>
              <v-col md="10" sm="10" cols="8" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap">{{ trialStayOptional.secondCheckInDate || '-' }} / {{ secondStayDays }}泊 / {{ secondFacilityText }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import createWebRequest from '@/mixins/createWebRequest'

export default {
  name: 'MainFormTrialStayOptionalShow',
  mixins: [
    createWebRequest
  ],
  data () {
    return {}
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  mounted () {
  },
  computed: {
    hasExistItemData () {
      return [
        this.hasExistItemDataRow1,
        this.hasExistItemDataRow2
      ].some(ele => !!ele)
    },
    hasExistItemDataRow1 () {
      return true
    },
    hasExistItemDataRow2 () {
      return true
    },
    firstStayDays () {
      return this.trialStayOptional.firstStayDays ? this.trialStayOptional.firstStayDays : '-'
    },
    secondStayDays () {
      return this.trialStayOptional.secondStayDays ? this.trialStayOptional.secondStayDays : '-'
    },
    firstFacilityText () {
      return this.trialStayOptional.firstFacility?.name ? this.trialStayOptional.firstFacility?.name : '-'
    },
    secondFacilityText () {
      return this.trialStayOptional.secondFacility?.name ? this.trialStayOptional.secondFacility?.name : '-'
    }
  },
  created () {
    this.typeApply = this.type
  }
}
</script>

<style lang="scss">
.mt-30 {
  margin-top: 30px;
}
</style>
